import axios from 'axios';
import CONST from '@/modules/constants';
import storage from '@/modules/storage';
import restful from '@/modules/restful';
import store from '@/store';

/**
 * UserClaim 정보
 * client, company, userId, userName, empCode, positionCode, positionName, deptCode, deptName
 */

const userStore = {
    namespaced: true,
    state: {
        auth: 'R3',
        roleNames: [],
        pageAuth: [], //페이지별 권한
        userClaim: {},

        //isLogin: false,
        //isLoginError: false
    },
    getters: {
        getAccount(state) {
            return state.userClaim;
        },
    },
    mutations: {
        setUserClaim(state, payload) {
            //state.isLogin = true;
            //state.isLoginError = false;
            state.userClaim = payload;
            localStorage.setItem(CONST.USER_CLAIM, JSON.stringify(payload));
        },
        /*
        loginError(state) {
            state.isLogin = false;
            state.isLoginError = true;
        },

         */
        logout(state) {
            // state.isLogin = false;
            // state.isLoginError = false;
            // state.userInfo = null;
            storage.removeLocalStorage(CONST.USER_CLAIM);
            storage.removeLocalStorage(CONST.SESSION_ID);
            state.userClaim = {};
        },
        /**
         * 권한 설정
         * R1: administrator
         * R2: 인사관리자
         * R3: 교육관리자
         * R4...
         * RZ: 개발자
         */
        setUserAuth(state) {
            state.auth = '';
            //console.log('role', state.userClaim.userRole);
            if (
                !state.userClaim.roleList ||
                Object.keys(state.userClaim.roleList).length === 0
            ) {
                state.auth = null;
            } else {
                state.userClaim.roleList.forEach(role => {
                    if (role.adminYn == 'Y') {
                        state.auth = 'R1';
                        return false;
                    } else if (role.roleId == 'R004') {
                        state.auth = 'R2';
                    }
                });

                state.roleNames = [];
                state.userClaim.roleList.forEach(role => {
                    state.roleNames.push(role.roleNm);
                });
                // console.log('==>',state.roleNames)
            }
        },

        /**
         * 페이지별 권한 setter
         */
        setPageAuth(state, payload) {
            state.pageAuth = payload; //새로고침시 데이터가 사라지므로 localStorage를 사용할것
            localStorage.setItem('ess-page-auth', JSON.stringify(payload));
        },
    },
    actions: {
        //ES6문법: https://joshua1988.github.io/web-development/translation/essential-es6-features-for-vuejs/
        //첫번째 인자는 기본적으로 context: state, rootState, commit, dispatch, getters, rootGetters
        login({ dispatch, commit }, { username, password }) {
            //return axios.post(`${resourceHost}/login`, {username, password})

            const request = axios.create({
                baseURL: CONST.BACKEND_URL,
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded; charset=utf-8',
                },
            });

            return new Promise((resolve, reject) => {
                const keepLogin =
                    storage.getLocalStorage(CONST.KEEP_LOGIN_KEY) == 'Y';
                axios
                    .post(`${CONST.BACKEND_URL}/v1/api/login`, {
                        username,
                        password,
                    })
                    .then(response => {
                        let isSuccess = false;
                        if (keepLogin) {
                            //console.log("status==>"+response.status);
                            //console.log(response.headers[TOKEN.REFRESH]);
                            //console.log(response.headers[TOKEN.ACCESS]);
                            let tokens = {};
                            if (response.headers[CONST.TOKEN.ACCESS]) {
                                isSuccess = true;
                                tokens[CONST.TOKEN.ACCESS] =
                                    response.headers[CONST.TOKEN.ACCESS];
                                tokens[CONST.TOKEN.REFRESH] =
                                    response.headers[CONST.TOKEN.REFRESH];

                                storage.setLocalStorage(
                                    CONST.TOKEN.ACCESS,
                                    tokens[CONST.TOKEN.ACCESS],
                                );
                                storage.setLocalStorage(
                                    CONST.TOKEN.REFRESH,
                                    tokens[CONST.TOKEN.REFRESH],
                                );
                            }
                        } else {
                            //session login
                            if (response.headers[CONST.SESSION_ID]) {
                                isSuccess = true;
                                storage.setLocalStorage(
                                    CONST.SESSION_ID,
                                    response.headers[CONST.SESSION_ID],
                                );
                            } else {
                                if (
                                    response.headers.spring_security_code ===
                                    'MUSTCHANGEPASSWORD'
                                ) {
                                    let error = {
                                        code: response.headers
                                            .spring_security_code,
                                        msg: '비밀번호가 초기화되었습니다.<br>비밀번호를 변경해 주십시오.',
                                    };
                                    reject(error);
                                }
                            }
                        }
                        if (isSuccess) {
                            return dispatch('getMemberInfo').then(() => {
                                resolve();
                            });
                        } else {
                            //console.log(response.headers.spring_security_code);
                            let message = '';
                            const callSa = ' 관리자에게 문의하십시오.';
                            switch (response.headers.spring_security_code) {
                                case 'E100':
                                    message = `등록된 권한이 존재하지 않습니다.<br>${callSa}`;
                                    break;
                                case 'E200':
                                    message =
                                        '로그인 정보가 올바르지 않습니다.<br>다시 확인하십시오.';
                                    break;
                                case 'E300':
                                    message =
                                        '로그인 정보가 올바르지 않습니다.<br>다시 확인하십시오.';
                                    break;
                                case 'E400':
                                    message = `연속 5회이상 로그인 실패로 계정이 잠겼습니다.<br>
                                        ${response.headers[CONST.TOKEN.REFRESH]}
                                        분 이후 잠금해제됩니다.`;
                                    break;
                                case 'E500':
                                    message = `미접속기간이 90일 경과하여 계정이 비활성되었습니다.<br>
                                        ${callSa}`;
                                    break;
                                case 'E600':
                                    message = `등록하신 계정이 이메일 미인증 상태입니다.<br>
                                        ${callSa}`;
                                    break;
                                default:
                                    message = `로그인이 실패하였습니다. ${callSa}`;
                            }
                            let error = {
                                code: response.headers.spring_security_code,
                                msg: message,
                            };
                            reject(error);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        let message = {
                            code: '500',
                            msg: '시스템 에러가 발생했습니다.<br>관리자에게 문의하십시오',
                        };
                        reject(message);
                    });
            });
        },

        ssoLogin({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(CONST.BACKEND_URL + '/v1/api/common/sso')
                    .then(response => {
                        let tokens = {};
                        if (response.headers[CONST.TOKEN.ACCESS]) {
                            tokens[CONST.TOKEN.ACCESS] =
                                response.headers[CONST.TOKEN.ACCESS];
                            tokens[CONST.TOKEN.REFRESH] =
                                response.headers[CONST.TOKEN.REFRESH];

                            localStorage.setItem(
                                CONST.TOKEN.ACCESS,
                                tokens[CONST.TOKEN.ACCESS],
                            );
                            localStorage.setItem(
                                CONST.TOKEN.REFRESH,
                                tokens[CONST.TOKEN.REFRESH],
                            );

                            commit('login', tokens);
                            return dispatch('getMemberInfo').then(() => {
                                return store
                                    .dispatch('menuStore/menuLoad')
                                    .then(() => {
                                        resolve();
                                    });
                            });
                        } else {
                            reject();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        reject();
                    });
            });
        },

        logout({ commit }) {
            //spring security logoutSuccessHandler에서 userClaim을 가져오지 못해 별도로 로그아웃 처리
            const sessionId = storage.getLocalStorage(CONST.SESSION_ID);
            if (sessionId) {
                axios
                    .put(CONST.BACKEND_URL + '/v1/api/common/user/logout')
                    .then(() => {
                        return new Promise(resolve => {
                            axios
                                .post(CONST.BACKEND_URL + '/v1/api/logout')
                                .then(() => {
                                    restful.clearTokenAndSession();
                                    commit('logout');
                                    resolve();
                                });
                        });
                    });
            } else {
                return new Promise(resolve => {
                    axios
                        .post(CONST.BACKEND_URL + '/v1/api/logout')
                        .then(() => {
                            restful.clearTokenAndSession();
                            commit('logout');
                            resolve();
                        });
                });
            }
        },
        getMemberInfo({ commit }) {
            const keepLogin =
                storage.getLocalStorage(CONST.KEEP_LOGIN_KEY) == 'Y';

            if (keepLogin) {
                //토큰을 가지고 유저정보가 있는지 확인
                //새로고침 -> 토큰만 가지고 멤버정보 확인 (DB조회 없음)
                return new Promise(resolve => {
                    let _userClaim = JSON.parse(
                        localStorage.getItem(CONST.USER_CLAIM),
                    );
                    if (Object.keys(_userClaim).length !== 0) {
                        commit('setUserClaim', _userClaim);
                        commit('setUserAuth');
                        resolve();
                    } else {
                        restful
                            .get('/v1/api/common/user/info/token')
                            .then(data => {
                                //console.log("data.map ==>", data.map);
                                commit('setUserClaim', data.map);
                                commit('setUserAuth');
                                resolve();
                            });
                    }
                });
            } else {
                return new Promise(resolve => {
                    restful
                        .get('/v1/api/common/user/info/session')
                        .then(data => {
                            //console.log("data.map ==>", data.map);
                            commit('setUserClaim', data.map);
                            commit('setUserAuth');
                            resolve();
                        });
                });
            }
            /*
            const token = localStorage.getItem(CONST.TOKEN.ACCESS);
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            console.log("token=>", JSON.parse(jsonPayload));
            const json = JSON.parse(jsonPayload);
            const claim = json['ess'];
            let arr = claim.split("#");
            let userClaim = {
                'client': arr[0],
                'company': arr[1],
                'empCode': arr[2],
                'userId': arr[3],
                'positionCode': arr[4],
                'positionName': arr[5],
                'deptCode': arr[6],
                'deptName': arr[7]
            };
            commit("setUserClaim", userClaim);
             */
        },

        /**
         * 페이지별 권한조회(배열)
         * @param commit
         * @returns {Promise<unknown>}
         */
        getPageAuth({ commit }) {
            return new Promise(resolve => {
                let _pageAuth = JSON.parse(
                    localStorage.getItem('ess-page-auth'),
                );
                if (Object.keys(_pageAuth) !== 0) {
                    commit('setPageAuth', _pageAuth);
                    resolve();
                } else {
                    restful
                        .get('/v1/api/common/members/pageauth')
                        .then(data => {
                            //console.log("data.map ==>", data.map);
                            commit('setPageAuth', data.list);
                            resolve();
                        });
                }
            });
        },

        refreshToken({ commit }) {
            let _headers = {};
            _headers[CONST.TOKEN.ACCESS] = localStorage.getItem(
                CONST.TOKEN.ACCESS,
            );
            _headers[CONST.TOKEN.REFRESH] = localStorage.getItem(
                CONST.TOKEN.REFRESH,
            );
            let _axios = axios.create({
                headers: _headers,
            });
            return new Promise((resolve, reject) => {
                _axios
                    .post(CONST.BACKEND_URL + '/v1/api/common/tokens/refresh')
                    .then(response => {
                        let _tokens = {};
                        if (response.headers[CONST.TOKEN.ACCESS]) {
                            _tokens[CONST.TOKEN.ACCESS] =
                                response.headers[CONST.TOKEN.ACCESS];
                            localStorage.setItem(
                                CONST.TOKEN.ACCESS,
                                _tokens[CONST.TOKEN.ACCESS],
                            );
                            commit('login', _tokens);
                            //return response;
                            resolve('resolve');
                        } else {
                            reject('토큰 발행이 실패하였습니다.');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        reject('토큰 발행이 실패하였습니다.');
                    });
            });
        },
    },
};

export default userStore;
