import { notify } from '@kyvg/vue3-notification';
//https://bestofvue.com/repo/kyvg-vue3-notification-vuejs-notification

const notification = {
    group: 'common-notification', //기본그룹
    duration: 10000, //유지시간
    speed: 1000,
    title: {
        success: 'Success',
        warning: 'Warning',
        error: 'Error',
        info: 'Information',
    },
    type: {
        success: 'success',
        warning: 'warn',
        error: 'error',
    },

    //세션만료 noti
    //세션만료가 여러개 나오는 현상을 제거하기 위해 group분리
    showGroup(code, mgs, group) {
        let type = '';
        let title = this.title.info;
        switch (code) {
            case 'W':
                title = this.title.warning;
                type = this.type.warning;
                break;
            case 'E':
                title = this.title.error;
                type = this.type.error;
                break;
            case 'S':
                title = this.title.success;
                type = this.type.success;
                break;
        }
        notify({
            group,
            duration: this.duration,
            speed: this.speed,
            title,
            text: mgs,
            type, //type이 없는 경우 info
        });
    },

    show(code, msg) {
        switch (code) {
            case 'E':
            case 'CE':
            case 'RE':
            case 'UE':
            case 'DE':
                console.error(`Notification ${msg || '에러가 발생했습니다.'}`);
                break;
        }
        switch (code) {
            //-----------------------------------------------------------------------
            case 'CE':
                this._notification(
                    this.title.error,
                    this.type.error,
                    msg || '등록 중 에러가 발생했습니다.',
                );
                break;
            case 'CS':
                this._notification(
                    this.title.success,
                    this.type.success,
                    msg || '등록되었습니다.',
                );
                break;

            //-----------------------------------------------------------------------
            case 'RE':
                this._notification(
                    this.title.error,
                    this.type.error,
                    msg || '조회 중 에러가 발생했습니다.',
                );
                break;
            case 'RS':
                this._notification(
                    this.title.success,
                    this.type.success,
                    msg || '조회 성공 !!',
                );
                break;

            case 'RW':
                this._notification(this.title.warning, this.type.warning, msg);
                break;

            //-----------------------------------------------------------------------
            case 'UE':
                this._notification(
                    this.title.error,
                    this.type.error,
                    msg || '수정 중 에러가 발생했습니다.',
                );
                break;
            case 'US':
                this._notification(
                    this.title.success,
                    this.type.success,
                    msg || '수정되었습니다.',
                );
                break;

            //-----------------------------------------------------------------------
            case 'DE':
                this._notification(
                    this.title.error,
                    this.type.error,
                    msg || '삭제 중 에러가 발생했습니다.',
                );
                break;
            case 'DS':
                this._notification(
                    this.title.success,
                    this.type.success,
                    msg || '삭제되었습니다.',
                );
                break;

            //-----------------------------------------------------------------------
            case 'I':
            case 'CI':
            case 'RI':
            case 'UI':
            case 'DI':
                this._notification(this.title.info, null, msg);
                break;

            //-----------------------------------------------------------------------
            case 'W':
            case 'CW':
            case 'UW':
            case 'DW':
                this._notification(this.title.warning, this.type.warning, msg);
                break;

            //-----------------------------------------------------------------------
            case 'E':
                this._notification(this.title.error, this.type.error, msg);
                break;
            case 'S':
                this._notification(this.title.success, this.type.success, msg);
                break;
        }
    },
    closeAll() {
        notify({ group: this.group, clean: true });
        notify({ group: 'session-notification', clean: true });
    },
    _notification(title, type, text) {
        notify({
            group: this.group,
            duration: this.duration,
            speed: this.speed,
            title,
            text,
            type, //type이 없는 경우 info
        });
    },
};

export default notification;
