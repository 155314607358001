<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col pt-5 text-center fs-1 text-primary">
                FSE팀 WIKI 사이트입니다.
            </div>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    components: {},
    props: [],
    data() {
        return {};
    },
    watch: {},
    computed: {},
    methods: {
        ...mapMutations({
            setLeftMenu: 'menuStore/setLeftMenu',
            setTopMenuStatus: 'menuStore/setTopMenuStatus',
            setLeftMenuStatus: 'menuStore/setLeftMenuStatus',
        }),
    },
    mounted: function () {
        //메뉴제어
        this.setTopMenuStatus(1);
        this.setLeftMenuStatus(0);
        // this.isDfocus = this.userClaim.email.indexOf('dfocus') > -1;
    },
    beforeUnmount() {},
    created: function (options) {},
};
</script>
<style scoped></style>
