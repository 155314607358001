import { createStore } from 'vuex';
// import requester from '@/common/requester';
import userStore from './modules/userStore.js';
import menuStore from './modules/menuStore.js';
import popupStore from './modules/popupStore';
import windowHeight from './modules/windowHeight';
import dropdownData from './modules/dropdownData.js';
// store 모듈 작성할 때 state, getters, mutaions 순서

export default createStore({
    modules: {
        userStore,
        menuStore,
        popupStore,
        dropdownData,
        windowHeight,
    },

    // state: {
    //     employeeList: null,
    //     headerMenu: null,
    //     menuTree: null,
    //     // routesData: null,
    //     selectedMenu: 'Home',
    // },
    // getters: {
    //     getSelectedMenu(state) {
    //         return state.selectedMenu;
    //     },
    // },
    // mutations: {
    //     /**
    //      * EmployeeList set
    //      */
    //     setEmployeeList(state, data) {
    //         state.employeeList = data;
    //     },
    //     /**
    //      * selected Menu set
    //      */
    //     setSelectedMenu(state, data) {
    //         state.selectedMenu = data;
    //     },
    // },
    // actions: {
    //     /**
    //      * EmployeeList 조회
    //      * @param commit
    //      * @returns {Promise<unknown>}
    //      */
    //     getEmployeeList({ commit }) {
    //         return new Promise(resolve => {
    //             requester.get('/api/getEmployees', {}).then(data => {
    //                 commit('setEmployeeList', data);
    //                 resolve();
    //             });
    //         });
    //     },
    //     /**
    //      * MenuTree 조회
    //      * @param commit
    //      * @returns {Promise<unknown>}
    //      */
    //     setSelectedTopMenu({ state, commit }, menu) {
    //         commit('setSelectedMenu', menu);
    //     },
    // },
});
