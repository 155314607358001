/**
 * Local Strorage Index
 * - KeepLogin = Y/N => 로그인 유지
 */

class storage {
    localPrefix = '_DFOCUS_LOCAL_';
    sessionPrefix = '_DFOCUS_SESSION_';

    constructor() {}

    setLocalStorage(key, value) {
        localStorage.setItem(`${this.localPrefix}${key}`, value);
    }

    getLocalStorage(key) {
        return localStorage.getItem(`${this.localPrefix}${key}`);
    }

    removeLocalStorage(key) {
        localStorage.removeItem(`${this.localPrefix}${key}`);
    }

    setSessionStorage(key, value) {
        sessionStorage.setItem(`${this.sessionPrefix}${key}`, value);
    }

    getSessionStorage(key) {
        return sessionStorage.getItem(`${this.sessionPrefix}${key}`);
    }

    removeSessionStorage(key) {
        sessionStorage.removeItem(`${this.localPrefix}${key}`);
    }
}

export default new storage();
