import restful from '@/modules/restful';
import commonMixin from '@/mixin/commonMixin';
const dropdownData = {
    namespaced: true,
    state: {
        common: {
            deptTreeList: [], //조직트리
            companyList: [], // 회사 리스트
            availableCompanyList: [], // 등록된 직원이 존재하는 회사
            categoryList: [], // 카테고리 리스트
            searchType: [], // 검색 종류
            roleList: [], // 권한 리스트
            solverList: [], // 담당자 리스트
            osList: [], // os 리스트
            solutionList: [], // 제품 리스트
            qnaCategoryList: [], // Q&A 카테고리 리스트
            caseCategoryList: [], // Case 분류 리스트
            menuNameList: [], // menu 이름 리스트
            caseSolutionList: [], // case 제품 리스트
            bbs1CategoryList: [],
            bbs2CategoryList: [],
            bbs3CategoryList: [],
            bbs4CategoryList: [],
            bbs4SubCategoryList: [],
            messageCategoryList: [],
            wordsTypeList: [], // 용어 타입 리스트
        },
        system: {
            RegulationCode: [], //규정종류
            batchList: [], //배치목록
        },
        case: {
            statusList: [], // 진행 상태
            contractList: [], //계약 구분 리스트
        },
        master: {
            licenseTypeCode: [], // company license type code
        },
    },
    getters: {},
    mutations: {
        setCommonCode(state, payload) {
            state.common[payload.groupCode] = payload.items;
        },
        setSystemCode(state, payload) {
            state.system[payload.groupCode] = payload.items;
        },
        setCaseCode(state, payload) {
            state.case[payload.groupCode] = payload.items;
        },
        setMasterCode(state, payload) {
            state.master[payload.groupCode] = payload.items;
        },
        clearCompanyList(state) {
            state.common.companyList = [];
            state.common.availableCompanyList = [];
        },
    },
    actions: {
        //조직트리 ----------------------------------------------------------------
        //조직/부서 목록 조회 (트리용)
        setDeptTreeList({ state, commit }) {
            return new Promise(resolve => {
                if (
                    Array.isArray(state.common.deptTreeList) &&
                    state.common.deptTreeList.length < 1
                ) {
                    restful.get('/v1/api/common/ddl/dept/tree').then(data => {
                        commit('setCommonCode', {
                            groupCode: 'deptTreeList',
                            items: data.list,
                        });
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        },
        // 회사 목록 조회
        setCompanyList({ state, commit }) {
            return new Promise(resolve => {
                if (
                    Array.isArray(state.common.companyList) &&
                    state.common.companyList.length < 1
                ) {
                    restful
                        .get('/v1/api/common/ddl/company')
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: 'companyList',
                                items: data.list,
                            });
                            resolve();
                        })
                        .catch(error => {
                            console.log('회사 목록 조회 error ->', error);
                            this.$notify.show(
                                'E',
                                '회사 목록을 불러오는데 실패했습니다.',
                            );
                        });
                } else {
                    resolve();
                }
            });
        },
        //규정 코드 목록 조회
        setRegulationCode({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000003'; //규정 그룹코드
                let className = 'RegulationCode';
                if (
                    Array.isArray(state.system.RegulationCode) &&
                    state.system.RegulationCode.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setSystemCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        //배치 목록 조회
        setBatchList({ state, commit }) {
            return new Promise(resolve => {
                if (
                    Array.isArray(state.system.batchList) &&
                    state.system.batchList.length < 1
                ) {
                    restful.get('/v1/api/common/ddl/batch').then(data => {
                        commit('setSystemCode', {
                            groupCode: 'batchList',
                            items: data.list,
                        });
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        },
        // 상태 리스트
        setStatusList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000009'; //케이스 상태 코드
                let className = 'statusList';
                if (
                    Array.isArray(state.case.statusList) &&
                    state.case.statusList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCaseCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 카테고리 리스트
        setCategoryList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000002'; // 게시판 카테고리 코드
                let className = 'categoryList';
                if (
                    Array.isArray(state.common.categoryList) &&
                    state.common.categoryList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 검색 리스트
        setSearchType({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000007'; //
                let className = 'searchType';
                if (
                    Array.isArray(state.common.searchType) &&
                    state.common.searchType.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 제품 리스트
        setSolutionList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000004'; // 제품 코드
                let className = 'solutionList';
                if (
                    Array.isArray(state.common.solutionList) &&
                    state.common.solutionList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // os 리스트
        setOsList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000011'; // 제품 코드
                let className = 'osList';
                if (
                    Array.isArray(state.common.osList) &&
                    state.common.osList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 사용자가 존재하는 회사 리스트
        setAvailableCompanyList({ state, commit }) {
            return new Promise(resolve => {
                let className = 'availableCompanyList';
                if (
                    Array.isArray(state.common.availableCompanyList) &&
                    state.common.availableCompanyList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/company/available`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 권한리스트
        setRoleList({ state, commit }) {
            return new Promise(resolve => {
                let className = 'roleList';
                if (
                    Array.isArray(state.common.roleList) &&
                    state.common.roleList.length < 1
                ) {
                    restful.get(`/v1/api/common/ddl/role`).then(data => {
                        commit('setCommonCode', {
                            groupCode: className,
                            items: data.list,
                        });
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        },
        // 담당자 리스트
        setSolverList({ state, commit }) {
            return new Promise(resolve => {
                let className = 'solverList';
                if (
                    Array.isArray(state.common.solverList) &&
                    state.common.solverList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/employees/solvernm`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // QNA 카테고리 리스트
        setQnaCategoryList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000012'; // 게시판 > Q&A 카테고리 코드
                let className = 'qnaCategoryList';
                if (
                    Array.isArray(state.common.qnaCategoryList) &&
                    state.common.qnaCategoryList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // Case 카테고리 리스트
        setCaseCategoryList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000013'; // Case 분류(카테고리) 코드
                let className = 'caseCategoryList';
                if (
                    Array.isArray(state.common.caseCategoryList) &&
                    state.common.caseCategoryList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // company license type code
        setLicenseTypeCode({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000015'; // company license type code
                let className = 'licenseTypeCode';
                if (
                    Array.isArray(state.master.licenseTypeCode) &&
                    state.master.licenseTypeCode.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setMasterCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 메뉴 목록 조회
        setMenuNameList({ state, commit }) {
            return new Promise(resolve => {
                if (
                    Array.isArray(state.common.menuNameList) &&
                    state.common.menuNameList.length < 1
                ) {
                    restful
                        .get('/v1/api/public/ddl/menu')
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: 'menuNameList',
                                items: data.list,
                            });
                            resolve();
                        })
                        .catch(error => {
                            console.log('회사 목록 조회 error ->', error);
                            this.$notify.show(
                                'E',
                                '회사 목록을 불러오는데 실패했습니다.',
                            );
                        });
                } else {
                    resolve();
                }
            });
        },

        // 계약구분리스트
        setContractList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000016'; // 게시판 카테고리 코드
                let className = 'contractList';
                if (
                    Array.isArray(state.case.contractList) &&
                    state.case.contractList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCaseCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },

        // 제품 리스트
        setCaseSolutionList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000017'; // 제품 코드
                let className = 'caseSolutionList';
                if (
                    Array.isArray(state.common.caseSolutionList) &&
                    state.common.caseSolutionList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 내부게시판 카테고리 리스트
        setBbs1CategoryList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000018';
                let className = 'bbs1CategoryList';
                if (
                    Array.isArray(state.common.bbs1CategoryList) &&
                    state.common.bbs1CategoryList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 개발 카테고리 리스트
        setBbs2CategoryList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000019';
                let className = 'bbs2CategoryList';
                if (
                    Array.isArray(state.common.bbs2CategoryList) &&
                    state.common.bbs2CategoryList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // TXD 카테고리 리스트
        setBbs3CategoryList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000020';
                let className = 'bbs3CategoryList';
                if (
                    Array.isArray(state.common.bbs3CategoryList) &&
                    state.common.bbs3CategoryList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 개발 WIKI 카테고리 리스트
        setBbs4CategoryList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000021';
                let className = 'bbs4CategoryList';
                if (
                    Array.isArray(state.common.bbs4CategoryList) &&
                    state.common.bbs4CategoryList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 개발 WIKI sub 카테고리 리스트
        setBbs4SubCategoryList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000022';
                let className = 'bbs4SubCategoryList';
                if (
                    Array.isArray(state.common.bbs4SubCategoryList) &&
                    state.common.bbs4SubCategoryList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 메시지 관리 카테고리 리스트
        setMessageCategoryList({ state, commit }) {
            return new Promise(resolve => {
                let groupCode = 'CT00000023';
                let className = 'messageCategoryList';
                if (
                    Array.isArray(state.common.messageCategoryList) &&
                    state.common.messageCategoryList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
        // 용어 사전 카테고리 리스트
        setWordsTypeList({ state, commit }) {
            let groupCode = 'CT00000024';
            let className = 'wordsTypeList';
            return new Promise(resolve => {
                if (
                    Array.isArray(state.common.wordsTypeList) &&
                    state.common.wordsTypeList.length < 1
                ) {
                    restful
                        .get(`/v1/api/common/ddl/codegroup/${groupCode}`)
                        .then(data => {
                            commit('setCommonCode', {
                                groupCode: className,
                                items: data.list,
                            });
                            console.log('testtest ', data.list);
                            resolve();
                        });
                } else {
                    resolve();
                }
            });
        },
    },
};
export default dropdownData;
