import {
    createRouter,
    createWebHistory,
    createWebHashHistory,
} from 'vue-router';
import Home from '../views/Home.vue';
import restful from '@/modules/restful';

const requireAuth = () => (to, from, next) => {
    console.log(
        '===========================================================================================',
    );
    console.log(`${from.path} ~ ${to.path}`);
    console.log(
        '===========================================================================================',
    );
    // if (to.path == '/') {
    //     //store.dispatch('userStore/ssoLogin').then(() =>{
    //     checkToken(next);
    //     //}).catch(() => {
    //     //    checkToken(next);
    //     //});
    // } else {
    //     checkToken(next);
    // }
    restful.checkSession();
    next();
};

const checkSome = () => (to, from, next) => {
    console.log(
        '===========================================================================================',
    );
    console.log(`${from.path} ~ ${to.path}`);
    console.log(
        '===========================================================================================',
    );
    next();
};

function loadView(view) {
    return () =>
        import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

const routes = [
    /********** 공통 **********/
    { path: '/', redirect: '/home' }, //메인
    {
        path: '/home',
        component: loadView('Home'),
        //component: loadView('common/home'),
        // beforeEnter: requireAuth(),
    },
    { path: '/login', component: loadView('common/login') }, //로그인
    { path: '/error', component: loadView('common/error') }, //Error
    // { path: '/:pathMatch(.*)', component: loadView('common/notFound') }, //404 not found
    {
        path: '/wiki/:seq',
        component: loadView('common/faq'),
        props: true,
    }, //FAQ 외부링크 연동
    // { path: '/:pathMatch(.*)', redirect: '/home' },
    {
        path: '/bod5000',
        component: loadView('board/bod5000'),
        beforeEnter: requireAuth(),
    }, //개발게시판
    {
        path: '/bod7000',
        component: loadView('board/bod7000'),
        beforeEnter: requireAuth(),
    }, //개발WIKI 게시판
    {
        path: '/sys9200',
        component: loadView('system/sys9200'),
        beforeEnter: requireAuth(),
    }, //암/복호화
    {
        path: '/mst5000',
        component: loadView('master/mst5000'),
        beforeEnter: requireAuth(),
    }, //용어사전
];

const router = createRouter({
    history: createWebHistory(), //createWebHashHistory(),
    routes,
});

export default router;
