/**
 * front-end 공통 상수
 */

// import $ from 'jquery';
let baseURL = process.env.VUE_APP_API_ENDPOINT_PROD;
const { host } = window.location;
console.log('host ===>', host);
// local
if (host.indexOf(process.env.VUE_APP_HOST_LOCAL) > -1) {
    baseURL = process.env.VUE_APP_API_ENDPOINT_LOCAL;
}

// 개발
else if (host === process.env.VUE_APP_HOST_DEV) {
    baseURL = process.env.VUE_APP_API_ENDPOINT_DEV;
}

// DEMO
else if (host === process.env.VUE_APP_HOST_DEMO) {
    baseURL = process.env.VUE_APP_API_ENDPOINT_DEMO;
}

// 운영
else if (host === process.env.VUE_APP_HOST_PROD) {
    baseURL = process.env.VUE_APP_API_ENDPOINT_PROD;
}
console.log('baseURL ===>', baseURL);

export default Object.freeze({
    dfocus: {
        address: '서울시 성동구 성수이로51 한라시그마밸리 304호',
        tel: '02-3452-3900',
        fax: '02-556-1269',
        mailhost: 'dfocus.net',
    },
    BACKEND_URL: process.env.NODE_ENV === 'development' ? '' : baseURL, //'34.64.200.255 http://csm.dfocus.net'
    // BACKEND_URL: process.env.NODE_ENV === 'production' ? '/' : '',
    YN: [
        { text: 'Y', value: 'Y' },
        { text: 'N', value: 'N' },
    ],
    KEEP_LOGIN_KEY: 'KeeyLogin',
    SESSION_ID: '_session_id_',
    USER_CLAIM: '_user_claim_',
    TOKEN: {
        ACCESS: 'dfocus_access_token',
        REFRESH: 'dfocus_refresh_token',
    },
    NOTICE_COOKIE: 'dess_notice_popup',
    LOGIN_API: '/v1/api/login',
    DDL_ALL: { text: '전체', value: '' },
    DDL_SELECT: { text: '선택', value: '' },
    codeGroup: {
        common: 'COMMON',
    },
    CASE_SOLVER: 'R004',
    DFOCUS_CODE: 'COM0001',
    ONOFF: [
        { text: 'ON', value: 'Y' },
        { text: 'OFF', value: 'N' },
    ],
    datePickerFormat: 'yyyy-MM-dd',
    monthPickerFormat: 'yyyy.MM',
    yearPickerFormat: 'yyyy',
    editorTools: [
        //'fontName',
        'formatting',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'fontSize',
        'foreColor',
        'backColor',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'insertUnorderedList',
        'insertOrderedList',
        'indent',
        'outdent',
        'subscript',
        'superscript',
        'createLink',
        'unlink',
        'insertImage',
    ],
    WikieditorTools: [
        'bold',
        'italic',
        'underline',
        'undo',
        'redo',
        'strikethrough',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'insertUnorderedList',
        'insertOrderedList',
        'insertUpperRomanList',
        'insertLowerRomanList',
        'indent',
        'outdent',
        'createLink',
        'unlink',
        'insertImage',
        'insertFile',
        'subscript',
        'superscript',
        'tableWizard',
        'createTable',
        'addRowAbove',
        'addRowBelow',
        'addColumnLeft',
        'addColumnRight',
        'deleteRow',
        'deleteColumn',
        'mergeCellsHorizontally',
        'mergeCellsVertically',
        'splitCellHorizontally',
        'splitCellVertically',
        'tableAlignLeft',
        'tableAlignCenter',
        'tableAlignRight',
        'viewHtml',
        'formatting',
        'cleanFormatting',
        'copyFormat',
        'applyFormat',
        'fontName',
        'fontSize',
        'foreColor',
        'backColor',
        'print',
    ],
    //kendo date format
    format: {
        ymd: 'yyyy-MM-dd',
        ymd_g: '{0:yyyy-MM-dd}', //grid용
        ymd_k: 'yyyy년 M월 dd일',
        ymd_e: 'MM.dd.yyyy.', //영문 출력용
        ymd_point: 'yyyy.MM.dd', //점으로 구분
        ym: 'yyyy.MM',
        ym_k: 'yyyy년 M월',
        yyyymmdd: 'yyyyMMdd',
        yyyymmddhhmmss: 'yyyy-MM-dd HH:mm:ss',
    },
    menuIds: {
        notice: { menuId: 'MN00000025', parentMenuId: 'MN00000003' }, //공지사항
        faq: { menuId: 'MN00000030', parentMenuId: 'MN00000003' },
        qna: { menuId: 'MN00000016', parentMenuId: 'MN00000003' },
        mycaseCustomer: { menuId: 'MN00000036', parentMenuId: 'MN00000032' },
        mycaseReceptionist: {
            menuId: 'MN00000037',
            parentMenuId: 'MN00000032',
        },
        mycaseResolver: { menuId: 'MN00000038', parentMenuId: 'MN00000032' },
        publicCase: { menuId: 'MN00000039', parentMenuId: 'MN00000032' },
    },
    regformat: {
        // email: /^[a-z0-9_\\.\\-]+@[a-z0-9\\-]+\.[a-z.]{2,6}$/, // 이메일 @앞에 대문자 , . 안됨
        email: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        password:
            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,25}$/, // 비밀번호
        mobilephone: /^(01[016789]{1})-[0-9]{3,4}-[0-9]{4}$/, // 휴대전화번호
        telephone: /^(070|02|0[3-9]{1}[0-9]{1})-[0-9]{3,4}-[0-9]{4}$/, // 전화번호
        overword: /(\w)\1\1\1/,
    },
    stepperItems: [
        {
            label: '임시저장',
            icon: 'k-i-save',
        },
        {
            label: '요청',
            icon: 'k-i-envelop-link',
        },
        {
            label: '접수완료',
            icon: 'k-i-track-changes-accept',
        },
        {
            label: '담당자지정',
            icon: 'k-i-share',
        },
        {
            label: '처리중',
            icon: 'k-i-track-changes',
        },
        {
            label: '종료',
            icon: 'k-i-check-circle',
        },
    ],

    //SpreadSheet
    spreadsheet: {
        bg: '#c5c5c5',
        bg2: '#d3e3f1',
        fc: '#000',
        c: 'center',
        fm: 'NotoSansKR',
        fs: '14',
    },
    get commonSheetCellStyle() {
        return {
            background: this.spreadsheet.bg2,
            textAlign: this.spreadsheet.c,
            verticalAlign: this.spreadsheet.c,
            fontSize: this.spreadsheet.fs,
            fontFamily: this.spreadsheet.fm,
        };
    },

    //block email list
    blockEmailList: ['hanmail.net', 'daum.net'],

    roleId: {
        administrator: 'R001',
        receptionist: 'R003',
    },
});
