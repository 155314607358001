import restful from '@/modules/restful';

export const makeTreeData = (items, menuId = '', link = '') =>
    items
        .filter(item => item[link] === menuId)
        .map(item => ({ ...item, children: makeTreeData(items, item.menuId) }));

export const makeRouterData = items =>
    items.map(item => {
        const folderPath = item.menuUrl.split('/');

        return {
            path: '/' + item.pageId,
            name: item.menuNm,
            component: () =>
                import('@/views/' + folderPath[0] + '/' + item.pageId + '.vue'),
            beforeEnter: restful.checkSession,
        };
    });

export const deepCopy = obj => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
            arr[i] = this.deepCopy(item);
            return arr;
        }, []);
    }

    if (obj instanceof Object) {
        return Object.keys(obj).reduce((newObj, key) => {
            newObj[key] = this.deepCopy(obj[key]);
            return newObj;
        }, {});
    }
};
