import axios from 'axios';
import CONST from '@/modules/constants';
import storage from '@/modules/storage';
import router from '@/router';
import store from '@/store';
import notify from '@/modules/notification';

// import App from '@/App.vue';
// import { createApp } from 'vue';

//const app = createApp(App);

class restful {
    _instance = null;
    _headers = {
        'Content-Type': 'application/json',
    };

    constructor(_axios) {
        const _keepLogin = storage.getLocalStorage(CONST.KEEP_LOGIN_KEY) == 'Y';

        if (_keepLogin) {
            this._headers[CONST.TOKEN.ACCESS] = this.getAccessToken();
        }

        this._instance = _axios.create({
            baseURL: CONST.BACKEND_URL,
            withCredentials: true,
            timeout: 30000, //첨부파일 용량 많을때 에러남 10 -> 30
            headers: this._headers,
        });

        /**
         * 요청 인터셉터
         */
        this._instance.interceptors.request.use(
            config => {
                this.checkSession(config);
                return config;
            },
            error => {
                // 요청 에러 직전 호출됩니다.
                this.notification('E', error);
                return Promise.reject(error);
            },
        );

        /**
         * 응답 인터셉터
         */
        this._instance.interceptors.response.use(
            response => {
                this.loading(false);
                // console.log('response', response);
                const _data = response?.data || {};
                if (response.status === 200) {
                    if (_data.status) {
                        return _data;
                    } else {
                        //file download

                        if (response.config?.responseType == 'arraybuffer') {
                            return response;
                        }
                        console.warn('resultMsg', _data.resultMsg);
                        this.notification('E', _data.resultMsg);

                        if (_data.httpStatus == 'UNAUTHORIZED') {
                            this.goLogin();
                        }
                        return Promise.reject(_data);
                    }
                } else {
                    console.log(
                        '# response.status is not 200 =====================================',
                    );
                    console.log(_data);
                    console.log(
                        '# response =======================================================',
                    );
                    return Promise.reject(_data);
                }
            },
            error => {
                console.error('interceptors.response error =>', error);
                this.loading(false);
                if (!error) {
                    this.notification(
                        'E',
                        '서버에 접근할 수 없습니다. 잠시 후에 다시 시도해 주세요.',
                        'session-notification',
                    );
                } else if (error.response && error.response.status == 999) {
                    //custom define status code
                    this.notification('E', '첨부파일이 존재하지 않습니다.');
                } else if (error.response && error.response.status == 401) {
                    this.clearTokenAndSession();
                    this.notification(
                        'W',
                        '세션이 만료되었습니다.',
                        'session-notification',
                    );
                    // alert('세션이 만료되었습니다.'); //여러번 나옴
                    setTimeout(() => {
                        window.location = '/';
                    }, 1000);
                    // this.goHome();

                    //router.push('/home').catch(() => {});
                } else if (error.response && error.response.status == 403) {
                    this.notification(
                        'E',
                        '해당 페이지에 접근 권한이 없습니다.',
                        'session-notification',
                    );
                    // this._goLogin();
                    //router.push('/home').catch(() => {});
                } else if (error.response && error.response.status == 404) {
                    this.notification(
                        'E',
                        '페이지 또는 API를 찾을 수 없습니다.',
                    );
                    //this._goLogin(); //팝업창 띄우기
                } else if (error.response && error.response.status == 405) {
                    //원인을 알수 없음... 아마도 토큰없을때??
                    //this.notification('E', '페이지 또는 API를 찾을 수 없습니다.');
                    //this._goLogin(); //팝업창 띄우기
                } else if (
                    error.response.data &&
                    error.response.data.httpStatus == 'UNAUTHORIZED'
                ) {
                    this.notification(
                        'E',
                        '해당 기능에 권한이 없습니다. 관리자에게 문의하십시오.',
                        'session-notification',
                    );
                } else {
                    this.notification('E', error);
                }
                return Promise.reject(error);
            },
        );
    }

    _request(method, url, paramObj) {
        const isNotLoading = url.indexOf('common') > -1;

        if (!isNotLoading) this.loading(true);

        //max 30초후 spinner 중지 (스크립트 에러발생시 동작안함)
        setTimeout(() => {
            this.loading(false);
        }, 30000);

        switch (method) {
            case 'get':
                return this._instance.request({
                    method,
                    url,
                    params: paramObj,
                });
            case 'file':
                return this._instance.request({
                    method: 'get',
                    url,
                    responseType: 'arraybuffer',
                });
            case 'upload':
                // this._headers['Content-Type'] = 'multipart/form-data';
                return this._instance.request({
                    method: 'post',
                    url,
                    data: paramObj,
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            default:
                return this._instance.request({
                    method,
                    url,
                    data: paramObj,
                });
        }
    }

    //==============================================================================
    // 외부 호출 함수
    //==============================================================================
    /**
     * refresh가 여러번 실행 방지
     * @returns true
     */
    async checkSession(config) {
        if (config?.url && config.url.indexOf('/v1/api/common') > -1)
            return true;
        if (config?.url && config.url.indexOf('/v1/api/public') > -1)
            return true;

        const _keepLogin = storage.getLocalStorage(CONST.KEEP_LOGIN_KEY) == 'Y';
        const _sessionId = storage.getLocalStorage(CONST.SESSION_ID);

        if (_keepLogin) {
            const status = await this.checkToken();
            if (!status) this.goLogin();
        } else {
            if (!_sessionId || _sessionId.length < 32) {
                // this.goLogin();
                this.clearTokenAndSession();
                this.goHome();
            }
        }
        return true;
    }

    get(uri, paramObj) {
        return this._request('get', uri, paramObj);
    }

    post(uri, paramObj) {
        return this._request('post', uri, paramObj);
    }

    put(uri, paramObj) {
        return this._request('put', uri, paramObj);
    }

    delete(uri, paramObj) {
        return this._request('delete', uri, paramObj);
    }

    file(uri) {
        return this._request('file', uri, null);
    }

    upload(uri, paramObj) {
        return this._request('upload', uri, paramObj);
    }

    //==============================================================================
    // Token / Session 관련 함수
    //==============================================================================
    getAccessToken() {
        return storage.getLocalStorage(CONST.TOKEN.ACCESS);
    }

    getRefreshToken() {
        return storage.getLocalStorage(CONST.TOKEN.REFRESH);
    }

    clearTokenAndSession() {
        storage.removeLocalStorage(CONST.TOKEN.ACCESS);
        storage.removeLocalStorage(CONST.TOKEN.REFRESH);
        storage.removeLocalStorage(CONST.SESSION_ID);
        localStorage.removeItem(CONST.USER_CLAIM);
        localStorage.setItem('wiki-selected-top-menu', null);
    }

    /**
     * Token (Access & Refresh)을 체크하여 refresh 또는 로그인 리다이렉트
     */
    async checkToken() {
        if (!this.getAccessToken()) this.goLogin();
        if (!this.checkAccessTokenValidation()) {
            console.warn('##### expired Access-Token !!!');
            if (this.checkRefreshTokenValidation()) {
                return await this.refreshAccessToken();
            } else {
                console.error('##### expired Refresh-Token !!!');
                this.notification(
                    'I',
                    '로그인 토큰이 만료되었습니다. 로그인 페이지로 이동합니다.', // todo 다국어
                );
                this.goLogin();
            }
        } else {
            return true;
        }
    }

    /**
     * Check Access Token Validation
     * @returns boolean
     */
    checkAccessTokenValidation() {
        const cat = this.getAccessToken();
        if (!cat) {
            console.error('##### no access token !!!');
            return false;
        }

        // console.log(">>> accesstoken: "+cat);
        // console.log(">>> refreshtoken: "+this._getRefreshToken());
        const claim = cat.substring(cat.indexOf('.') + 1, cat.lastIndexOf('.'));
        if (!claim) {
            console.error('##### no access-token claim !!!');
            return false;
        }
        // console.log(">>> claim: "+claim);

        //atob deprecated
        const base64Claim = Buffer.from(claim, 'utf8').toString('base64');
        //const exp = JSON.parse(atob(claim)).exp * 1000;
        const exp = JSON.parse(base64Claim).exp * 1000;

        const now = new Date().getTime();
        const limit = 10 * 60 * 1000; // 10분
        if (exp - now <= limit) {
            return false; // refresh 필요
        }
        return true;
    }

    /**
     * Check Refresh Token Validation
     * @returns boolean
     */
    checkRefreshTokenValidation() {
        const cat = this.getRefreshToken();
        // console.log(">>> refreshtoken: "+cat);
        if (!cat) {
            console.error('##### no refresh token !!!');
            return false;
        }
        const claim = cat.substring(cat.indexOf('.') + 1, cat.lastIndexOf('.'));
        if (!claim) {
            console.error('##### no refresh-token claim !!!');
            return false;
        }
        //console.log(">>> claim: "+claim);

        //atob deprecated
        const base64Claim = Buffer.from(claim, 'utf8').toString('base64');
        //const exp = JSON.parse(atob(claim)).exp * 1000;
        const exp = JSON.parse(base64Claim).exp * 1000;
        const now = new Date().getTime();
        if (exp <= now) {
            return false;
        }
        return true;
    }

    async refreshAccessToken() {
        //todo return boolean
        // store
        //   .dispatch('userStore/refreshToken')
        //   .then(() => {
        //     console.log('>>> success re-created Access-Token !!!');
        //     // console.groupEnd();
        //     this._isRefreshing = false;
        //   })
        //   .catch(error => {
        //     console.error(error);
        //     // console.groupEnd();
        //     this._goLogin();
        //   });
    }

    goLogin() {
        this.clearTokenAndSession();
        //이미 이동할 주소에 있기 때문에 발생
        //router.push('/login').catch(() => {});
    }

    goHome() {
        //이미 이동할 주소에 있기 때문에 발생
        router.push('/home').catch(() => {});
    }

    notification(code, msg, group) {
        //Vue.prototype.$notification.show(code, msg);
        if (group) {
            notify.showGroup(code, msg, group);
        } else {
            notify.show(code, msg);
        }
    }

    loading(isLoading) {
        // Vue.emitter.emit('isLoading', isLoading);
        store.commit('menuStore/setLoading', isLoading);
    }
}
export default new restful(axios);
